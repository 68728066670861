import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'

export const GoalControls = styled(Paper)({
  justifyContent: 'space-between',
  flexDirection: 'column',
  margin: '8px 0',
  padding: 12,
  maxWidth: 360,

  '@media (max-width: 360px)': {
    padding: 8
  },
  '@media (min-width: 768px)': {
    display: 'flex',
    margin: 0,
    padding: 24,
    minWidth: 280,
    height: 320
  }
})

export const GoalGeneratorTitle = styled('div')(({ theme }) => ({
  ...theme.typography.h6,
  paddingBottom: 8,

  '@media (min-width: 768px)': {
    ...theme.typography.h5,
    paddingBottom: 24
  }
}))

export const ExpansionsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
})

export const ExpansionSwitch = styled(FormControlLabel)({})

export const ButtonBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '16px 0',

  '@media (min-width: 768px)': {
    margin: '32px 0 0 0',
    flexDirection: 'column-reverse',
    maxWidth: 320,
    flex: 'auto'
  }
})

export const ResetGoalsButton = styled(Button)({})

export const GetGoalsButton = styled(Button)({})
