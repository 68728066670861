import { createTheme } from '@mui/material/styles'

export const wingspanTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0670bb'
    },
    reset: {
      main: '#AB1C36'
    },
    baseBlue: {
      main: '#2aa2c9'
    },
    europeanPurple: {
      main: '#664972'
    },
    oceaniaOrange: {
      main: '#FDB716'
    },
    asiaPink: {
      main: '#EE4F96'
    },
  }
})
