import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const PageContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  top: 16,
  left: 0,
  margin: '0 16px',
  paddingBottom: 24,

  '@media (min-width: 768px)': {
    top: 80,
    margin: '0 2% 0 2%'
  }
})

export const GoalGenerator = styled(Box)({

  '@media (min-width: 768px)': {
    display: 'flex'
  }
})
