import React from 'react'
import Switch from '@mui/material/Switch'
import {
  GoalControls,
  GoalGeneratorTitle,
  ExpansionsContainer,
  ExpansionSwitch,
  GetGoalsButton,
  ResetGoalsButton,
  ButtonBox
} from './GoalControlsArea.styled'

export function GoalControlsArea ({
  togglesDisabled,
  isBaseToggled,
  isEuropeanToggled,
  isOceaniaToggled,
  toggleBaseGoals,
  toggleEuropeanExpansion,
  toggleOceaniaExpansion,
  resetGoals,
  getGoals,
  disableGetGoals
}) {
  return (
    <GoalControls elevation={4}>
      <GoalGeneratorTitle>Goal Generator</GoalGeneratorTitle>
      <ExpansionsContainer>
        <ExpansionSwitch
          control={
            <Switch
              disabled={togglesDisabled}
              checked={isBaseToggled}
              onChange={toggleBaseGoals}
              name='Base'
              color='baseBlue'
            />
          }
          label='Base Game Goals'
        />
        <ExpansionSwitch
          control={
            <Switch
              disabled={togglesDisabled}
              checked={isEuropeanToggled}
              onChange={toggleEuropeanExpansion}
              name='Euro'
              color='europeanPurple'
            />
          }
          label='European Expansion'
        />
        <ExpansionSwitch
          control={
            <Switch
              disabled={togglesDisabled}
              checked={isOceaniaToggled}
              onChange={toggleOceaniaExpansion}
              name='Oceania'
              color='oceaniaOrange'
            />
          }
          label='Oceania Expansion'
        />
      </ExpansionsContainer>

      <ButtonBox>
        <ResetGoalsButton
          variant='contained'
          onClick={resetGoals}
          color='reset'
        >
          Reset Goals
        </ResetGoalsButton>
        <GetGoalsButton
          variant='contained'
          disabled={disableGetGoals}
          onClick={getGoals}
        >
          Get Goals
        </GetGoalsButton>
      </ButtonBox>
    </GoalControls>
  )
}
