import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import Switch from '@mui/material/Switch'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export function FAQ () {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button variant='outlined' onClick={handleClickOpen}>
        FAQ
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ zIndex: 10 }}
      >
        <AppBar sx={{ position: 'sticky' }}>
          <Toolbar>
            <Typography
              component='div'
              sx={{ flexGrow: 1 }}
            >
              Frequently Asked Questions
            </Typography>
            <IconButton
              edge='end'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ padding: 3 }}>
          <Typography variant='h6' component='h2'>
            What is this?
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <p>
              The Wingspan Goal Generator is an efficient method to randomly generate your four End of Round Goals for the board game Wingspan.
            </p>
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography variant='h6' component='h2'>
            How does it work?
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <p>First, decide which goals to include in your game. The standard game's goals are enabled by default</p>
            <p>The European and/or Oceania goals can be included by enabling their respective <Switch /> found in Goal Generator.</p>
            <p>Then press <Button variant='contained'>Get Goals</Button> to randomly select four End of Round Goals to be drawn in the Draw Area.</p>
            <p>Multiple games can be played without repeated goals by pressing <Button variant='contained'>Get Goals</Button></p>
            <p>I've taken care to make sure you won't draw both the back and front of the same Goal Tile</p>
            <p>Finally, when you have run out of goals or would like to reshuffle all the goals press <Button variant='contained' color='reset'>Reset Goals</Button> to
              the reset the generator to its default state.
            </p>
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography variant='h6' component='h2'>
            Why did you make this?
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <p>
              My inspritation sprang from my love of
              creating tools to improve one's quality of life using code,
              and indentifing an opportunity to apply that to my love of Wingspan.
            </p>
            <p>
              The problem this tool attempts to solve is the lack of ease and consistency in drawing
              End of Round Goals with the physical Goal Tiles.
            </p>
            <p>
              I was not satisfied with shuffling the Goal Tiles
              in my hands and letting them fall out from an
              opening in my hands. This often caused multiple
              Goal Tiles to fall out and/or fall onto the ground.
            </p>
            <p>
              I identified that the Goal Tiles were a collection of objects that I could translate into data and then program a shuffling and drawing functionality to more efficiently draw goals and to improve my coding skills.
            </p>
          </Typography>
        </Box>
      </Dialog>
    </div>
  )
}
