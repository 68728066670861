const baseGameGoals = [
  {
    id: 1,
    goalType: 'base',
    goalTile: {
      front: {
        description: 'Birds in Wetland Habitat',
        svg: './img/BaseGoals/BirdsInWetlandHabitat.svg'
      },
      back: {
        description: 'Eggs in Wetlands Habitat',
        svg: './img/BaseGoals/EggsInWetlandsHabitat.svg'
      }
    }

  },
  {
    id: 2,
    goalType: 'base',
    goalTile: {
      front: {
        description: 'Birds in Forest Habitat',
        svg: './img/BaseGoals/BirdsInForestHabitat.svg'
      },
      back: {
        description: 'Eggs in Forest Habitat',
        svg: './img/BaseGoals/EggsInForestHabitat.svg'
      }
    }
  },
  {
    id: 3,
    goalType: 'base',
    goalTile: {
      front: {
        description: 'Birds in Grassland Habitat',
        svg: './img/BaseGoals/BirdsInGrasslandHabitat.svg'
      },
      back: {
        description: 'Eggs in Grassland Habitat',
        svg: './img/BaseGoals/EggsInGrasslandHabitat.svg'
      }
    }
  },
  {
    id: 4,
    goalType: 'base',
    goalTile: {
      front: {
        description: 'Bowl Nest Birds with Eggs',
        svg: './img/BaseGoals/BowlNestBirdWithEgg.svg'
      },
      back: {
        description: 'Eggs in Bowl Nests',
        svg: './img/BaseGoals/EggsInBowlNests.svg'
      }
    }
  },
  {
    id: 5,
    goalType: 'base',
    goalTile: {
      front: {
        description: 'Tree Nest Birds with Eggs',
        svg: './img/BaseGoals/TreeNestBirdWithEgg.svg'
      },
      back: {
        description: 'Eggs in Tree Nests',
        svg: './img/BaseGoals/EggsInTreeNest.svg'
      }
    }
  },
  {
    id: 6,
    goalType: 'base',
    goalTile: {
      front: {
        description: 'Ground Nest Birds with Eggs',
        svg: './img/BaseGoals/GroundNestBirdWithEgg.svg'
      },
      back: {
        description: 'Eggs in Ground Nests',
        svg: './img/BaseGoals/EggsInGroundNest.svg'
      }
    }
  },
  {
    id: 7,
    goalType: 'base',
    goalTile: {
      front: {
        description: 'Stick Nest Birds with Eggs',
        svg: './img/BaseGoals/StickNestBirdWithEgg.svg',
      },
      back: {
        description: 'Eggs in Stick Nests',
        svg: './img/BaseGoals/EggsInStickNest.svg'
      }
    }
  },
  {
    id: 8,
    goalType: 'base',
    goalTile: {
      front: {
        description: 'Sets of Eggs in Each Habitat',
        svg: './img/BaseGoals/SetsOfEggsInEachHabitat.svg'
      },
      back: {
        description: 'Total Cards',
        svg: './img/BaseGoals/TotalCards.svg'
      }
    }
  }
]

const europeanExpansionGoals = [
  {
    id: 9,
    goalType: 'european',
    goalTile: {
      front: {
        description: 'Food Cost of Played Birds',
        svg: './img/EuropeanGoals/FoodCostOfPlayedBirds.svg'
      },
      back: {
        description: 'Birds with Tucked Cards',
        svg: './img/EuropeanGoals/BirdsWithTuckedCards.svg'
      }
    }
  },
  {
    id: 10,
    goalType: 'european',
    goalTile: {
      front: {
        description: 'Food in Personal Supply',
        svg: './img/EuropeanGoals/FoodInPersonalSupply.svg'
      },
      back: {
        description: 'Cards in Hand',
        svg: './img/EuropeanGoals/CardsInHand.svg'
      }
    }
  },
  {
    id: 11,
    goalType: 'european',
    goalTile: {
      front: {
        description: 'Birds in One Row',
        svg: './img/EuropeanGoals/BirdsInOneRow.svg'
      },
      back: {
        description: 'Filled Columns',
        svg: './img/EuropeanGoals/FilledColumns.svg'
      }
    }
  },
  {
    id: 12,
    goalType: 'european',
    goalTile: {
      front: {
        description: 'Brown Powers',
        svg: './img/EuropeanGoals/BrownPowers.svg'
      },
      back: {
        description: 'White and No Powers',
        svg: './img/EuropeanGoals/WhiteAndNoPowers.svg'
      }
    }
  },
  {
    id: 13,
    goalType: 'european',
    goalTile: {
      front: {
        description: 'Birds with No Egg',
        svg: './img/EuropeanGoals/BirdsWithNoEgg.svg'
      },
      back: {
        description: 'Worth Greater Than 4',
        svg: './img/EuropeanGoals/WorthGreaterThan4.svg'
      }
    }
  }
]

const oceaniaExpansionGoals = [
  {
    id: 14,
    goalType: 'oceania',
    goalTile: {
      front: {
        description: 'Beak Pointing Left',
        svg: './img/OceaniaGoals/BeakPointingLeft.svg'
      },
      back: {
        description: 'Beak Pointing Right',
        svg: './img/OceaniaGoals/BeakPointingRight.svg'
      }
    }
  },
  {
    id: 15,
    goalType: 'oceania',
    goalTile: {
      front: {
        description: 'No Goal',
        svg: './img/OceaniaGoals/NoGoal.svg'
      },
      back: {
        description: 'Mouse and Fish Food Cost',
        svg: './img/OceaniaGoals/MouseAndFishFoodCost.svg'
      }
    }
  },
  {
    id: 16,
    goalType: 'oceania',
    goalTile: {
      front: {
        description: 'Cubes on "Play a Bird"',
        svg: './img/OceaniaGoals/CubesOnPlayABird.svg'
      },
      back: {
        description: 'Worth Less Than Or Equal To 3',
        svg: './img/OceaniaGoals/WorthLessThanEqualTo3.svg'
      }
    }
  },
  {
    id: 17,
    goalType: 'oceania',
    goalTile: {
      front: {
        description: 'Worm Food Cost',
        svg: './img/OceaniaGoals/WormFoodCost.svg'
      },
      back: {
        description: 'Berry and Grain Food Cost',
        svg: './img/OceaniaGoals/BerryAndGrainFoodCost.svg'
      }
    }
  }
]

export { baseGameGoals, europeanExpansionGoals, oceaniaExpansionGoals }
