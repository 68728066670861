import React from 'react'
import {
  GoalCard,
  GoalInformationContainer,
  GoalInformation,
  RoundNumber,
  GoalDescription,
  GoalSVG
} from './GoalCard.styled'

export function GoalCardComponent ({ currentGameGoal, index }) {
  return (
    <GoalCard elevation={24} key={index}>
      <GoalInformationContainer>
        <GoalInformation>
          <RoundNumber>
            Round {index + 1}
          </RoundNumber>
          <GoalDescription>
            {currentGameGoal.description}
          </GoalDescription>
        </GoalInformation>
      </GoalInformationContainer>
      <GoalSVG
        component='img'
        image={currentGameGoal.svg}
        alt={currentGameGoal.description}
      />
    </GoalCard>
  )
}
