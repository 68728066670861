import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import {
  PageContainer,
  GoalGenerator
} from './GoalDrawApp.styled'
import collection from 'lodash/collection'

import { GoalControlsArea } from '../GoalControlsArea/GoalControlsArea'
import { DrawArea } from '../DrawArea/DrawArea'

import { INIT_GOALS, EUROPEAN_GOALS, OCEANIA_GOALS, SIZE_OF_HANDS } from '../Library/sourceOfTruth'

function pickGoalSide (goal) {
    return Math.floor(Math.random() * 2) ? goal.goalTile.front : goal.goalTile.back
}

export function GoalDrawApp () {
  const [goals, setGoals] = useState([])
  const [nextGoals, setNextGoals] = useState(null)
  const [isBaseToggled, setIsBaseToggled] = useState(JSON.parse(localStorage.getItem('isBaseToggled')) ?? true)
  const [isEuropeanToggled, setIsEuropeanToggled] = useState(JSON.parse(localStorage.getItem('isEuropeanToggled')) ?? false)
  const [isOceaniaToggled, setIsOceaniaToggled] = useState(JSON.parse(localStorage.getItem('isOceaniaToggled')) ?? false)

  useEffect(() => {
    localStorage.setItem('isBaseToggled', JSON.stringify(isBaseToggled))
    localStorage.setItem('isEuropeanToggled', JSON.stringify(isEuropeanToggled))
    localStorage.setItem('isOceaniaToggled', JSON.stringify(isOceaniaToggled))
  }, [isBaseToggled, isEuropeanToggled, isOceaniaToggled])

  function resetGoals() {
    setGoals([])
    setNextGoals(null)
  }

  function getGoals () {
    let shuffledGoals = nextGoals
    if (shuffledGoals === null) {
      // initialize shuffled goals
      const availableGoals = [
        ...(isBaseToggled ? INIT_GOALS : []),
        ...(isEuropeanToggled ? EUROPEAN_GOALS : []),
        ...(isOceaniaToggled ? OCEANIA_GOALS : []),
      ]
      // now goals are shuffled and do not need to be reshuffled
      // until 'reset goals' is clicked
      shuffledGoals = collection.shuffle(availableGoals)
    }
    const currentGameGoals = shuffledGoals.splice(0, SIZE_OF_HANDS) // The shuffledGoals array has its first 4 values removed
    setNextGoals(shuffledGoals)
    setGoals(currentGameGoals.map(pickGoalSide))
  }

  const disableGetGoals = (isBaseToggled === false &&
                           isEuropeanToggled === false &&
                           isOceaniaToggled === false) ||
                          (nextGoals && nextGoals.length < SIZE_OF_HANDS)
  return (
    <Box>
      <PageContainer>
        <GoalGenerator>
          <GoalControlsArea
            // disable toggles if we have already begun drawing goal tiles
            togglesDisabled={nextGoals !== null}
            isBaseToggled={isBaseToggled}
            isEuropeanToggled={isEuropeanToggled}
            isOceaniaToggled={isOceaniaToggled}
            toggleBaseGoals={e => setIsBaseToggled(e.target.checked)}
            toggleEuropeanExpansion={e => setIsEuropeanToggled(e.target.checked)}
            toggleOceaniaExpansion={e => setIsOceaniaToggled(e.target.checked)}
            resetGoals={resetGoals}
            getGoals={getGoals}
            disableGetGoals={disableGetGoals}
          />
          <DrawArea goals={goals} />
        </GoalGenerator>
      </PageContainer>
    </Box>
  )
}
