import React from 'react'
import {
  GoalCards,
  DrawAreaTitle
} from './DrawArea.styled'
import { GoalCardComponent } from '../GoalCardComponent/GoalCard'

export function DrawArea ({ goals }) {
  return (
    <GoalCards elevation={4}>
      <DrawAreaTitle>Draw Area</DrawAreaTitle>
      {goals.map((currentGameGoal, index) => (
        <GoalCardComponent
          currentGameGoal={currentGameGoal}
          index={index}
          key={index}
        />
      ))
}
    </GoalCards>
  )
}
