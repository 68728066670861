import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'

export const GoalCard = styled(Card)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 8,
  height: 120,
  maxWidth: 360
})

export const GoalSVG = styled(CardMedia)({
  height: 120,
  width: 120
})

export const GoalInformationContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 200,
  '@media (max-width: 360px)': {
    maxWidth: 140
  },
  '@media (min-width: 768px)': {
    maxWidth: 300
  }
})

export const GoalInformation = styled(CardContent)({})

export const RoundNumber = styled('div')(({ theme }) => ({
  ...theme.typography.h5,
  '@media (max-width: 360px)': {
    ...theme.typography.h6
  }
}))

export const GoalDescription = styled('div')(({ theme }) => ({
  ...theme.typography.subtitle1,
  '@media (max-width: 360px)': {
    ...theme.typography.subtitle2
  }
}))
