import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'

export const DrawAreaTitle = styled('div')(({ theme }) => ({
  ...theme.typography.h6,
  paddingBottom: 8,
  alignSelf: 'flex-start',

  '@media (min-width: 768px)': {
    ...theme.typography.h5,
    paddingBottom: 24
  }
}))

export const GoalCards = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  height: 570,
  padding: 12,
  maxWidth: 360,

  '@media (max-width: 360px)': {
    padding: 8
  },
  '@media (min-width: 768px)': {
    marginLeft: '2%',
    padding: 24,
    width: 400
  }
})
