import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { wingspanTheme } from './Library/wingspanTheme'
import { Header } from './Header/Header'
import { GoalDrawApp } from './GoalDrawApp/GoalDrawApp'

function App () {
  return (
    <ThemeProvider theme={wingspanTheme}>

      <div className='App'>
        <Header />
        <GoalDrawApp />
      </div>
    </ThemeProvider>
  )
}

export default App
