import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { FAQ } from '../FAQ/FAQ'

export function Header () {
  return (
    <AppBar position='static'>
      <Toolbar>
        <Typography
          component='div'
          sx={{ flexGrow: 1 }}
        >
          Wingspan Goal Generator
        </Typography>
        <FAQ />
      </Toolbar>
    </AppBar>
  )
}
